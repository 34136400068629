.authors-vertical {
    .authors {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        a {
            color: black;
            text-decoration: none;
            width: 120px;
            display: block;
            text-align: center;
            font-size: 0.95rem;
            font-weight: 700;
            margin-top: 10px;
            img {
                width: 80px;
                height: 80px;
                object-fit: cover;
                border-radius: 50%;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
    }

    .title {
        color: white;
        background: var(--ion-color-coffee);
        padding: 10px 20px;
        font-weight: bold;
        letter-spacing: 3px;
        text-align: center;
    }
}
