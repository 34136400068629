#btn-auth-modal{
    &.no-padding{
        --padding: 0;
    }
    .cover{
        width: 27px;
        height: 27px;
        object-fit: cover;
        border-radius: 50%;
    }
}
#auth-modal {
    --width: 700px;
    --height: 500px;
    h3{
        font-weight: bold;
    }
    .box{
        padding: 0 20px;
    }

    .row {
        display: flex;
        align-items: center;
        margin-top: 25px;

        hr {
            border: 1px solid #999;
            width: 0;
            height: 250px;
            margin: 0;
        }

        .col {
            flex: 1;
            height: 400px;
            padding: 0 40px;
        }
    }
}
