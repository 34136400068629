nav#primary-menu {
    position: relative;
    background: var(--ion-color-primary);
    padding: 10px 0;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.68);
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.68);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.68);
    min-height: 50px;
    z-index: 2;
    font-family: var(--secondary-font-family);


    .menu {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }


        >li {
            //first level item
            position: relative;
            font-weight: 700;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 3px;
                width: 0;
                height: 3px;
                background: var(--ion-color-light);
                transition: 0.5s width;
            }

            a {
                color: var(--ion-color-light);
                text-decoration: none;
                padding: 10px 15px;
                white-space: nowrap;
                max-width: 200px;
                display: block;
            }

            >ul {
                //Second level Container
                width: 200px;
                background: white;
                position: absolute;
                left: 0;
                top: 50px;
                opacity: 0;
                height: 0;
                overflow: hidden;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.75);
                -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.75);

                a {
                    color: var(--ion-color-primary);
                }
                li:hover {
                    background: var(--ion-color-light-shade);
                }
            }

            &:hover>ul {
                height: max-content;
                top: 38px;
                opacity: 1;
                transition: 0.5s opacity, 0.5s top;
                z-index: 1;
            }
        }

        li:hover {
            &::after {
                width: 100%;
            }
        }
    }

    ion-buttons {
        display: block;
        padding: 3px 0;
    }

    ion-button {
        font-family: var(--primary-font-family);

        .svg-inline--fa {
            font-size: 16px;
            color: var(--ion-color-light);
        }
    }
    @media only screen and (max-width: 1025px) {
        .menu{
            justify-content: center;
        }
        .text-end{
            text-align: center;
        }
    }
    @media only screen and (min-width: 1025px) {
        .body{
            display: flex;
            justify-content: space-between;
        }
    }
    @media only screen and (max-width: 425px) {
        display: none;
    }
}
