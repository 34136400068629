
.btn-order{
    @media screen and (max-width:1024px) {
        width: 100%;
    }
}
.attr-selector{
    width: 100%;
    --background: rgba(var(--ion-color-tertiary-rgb, 0.5), 0.3);
}
