.slides {
    display: flex;

    .categories {
        width: 25%;
    }

    .slider {
        width: 75%;

        img {
            height: 450px;
            width: 100%;
            object-fit: cover;
        }
    }
}
