footer {
    background: #efefef;
    position: relative;
    z-index: 2;
    font-size: 16px;
    line-height: 1.3;
    a{
        color: black;
        text-decoration: none;
        &:hover {
            color: var(--ion-color-primary);
            text-decoration: underline;
            margin-left: 10px;
        }
    }
    .about {
        padding: 50px 0;

        h1 {
            color: #8f5959;
        }
        hr {
            color: var(--ion-color-primary);
            border: 1px solid;
            height: 0;
            width: 100px;
            margin: 10px 0 20px 0;
        }



        .item {
            margin-bottom: 10px;
            display: flex;


            a {
                text-decoration: none;
            }

            ion-icon {
                margin-right: 10px;
            }

            address,
            a {


                &:hover {
                    color: var(--ion-color-primary);
                    text-decoration: underline;
                    margin-left: 10px;
                }
            }
        }

        .links {
            display: flex;
            flex-direction: column;

            a {
                margin-bottom: 10px;
                margin-left: 10px;


            }
        }
    }
    .info{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-right: 10px;
        .copyright{
            min-width: 325px;
        }
        .links{
            width: max-content;
            a{
                margin-right: 10px;
            }
        }
        > div{
            margin-bottom: 30px;
        }
        @media only screen and (max-width: 768px) {

            margin-left: 10px;
        }
    }
}
