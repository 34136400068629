ion-tab-bar {
    background: #eeeeee;

}

ion-toolbar {
    --background: #eeeeee;
}

ion-content {
    --background: #eeeeee;
}

ion-tab-button {
    background: transparent;

    &.tab-selected {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#cccccc+0,eeeeee+100 */
        background: #cccccc;
        /* Old browsers */
        background: -moz-linear-gradient(top, #cccccc 0%, #eeeeee 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #cccccc 0%, #eeeeee 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #cccccc 0%, #eeeeee 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cccccc', endColorstr='#eeeeee', GradientType=0);
        /* IE6-9 */

    }
}

ion-label.label-floating {
    align-items: center;
    display: flex !important;

    ion-icon {
        padding-right: 10px;
        font-size: 20px;
    }
}

.text-center {
    text-align: center;
}

.text-start {
    text-align: left;
}

.text-end {
    text-align: right;
}

.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    strong {
        font-size: 20px;
        line-height: 26px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        color: #8c8c8c;
        margin: 0;
    }

    a {
        text-decoration: none;
    }
}

.list-item {
    &:hover {
        background-color: var(--ion-color-light-shade);
        box-shadow: none;
    }

    margin: 10px 5px;
    background-color: var(--ion-color-primary-contrast);
    padding: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
    box-shadow: 0px 1px 4px 0px #000;
    border-radius: 5px;

    img {
        // padding: 10px 0;
        margin-right: 20px;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        box-shadow: 0px 1px 4px 0px #000;
    }

    .place-holder {
        display: block;
        padding: 10px;
        margin-right: 20px;
        border-radius: 50%;
        box-shadow: 0px 1px 4px 0px #000;

        svg {
            width: 60px;
        }
    }

    h4 {
        line-height: 1.3;
        overflow: hidden;
    }
}

.section-content {
    overflow: auto;
    padding: 20px;
}

#post-body {
    background-color: white;
}

dr-post {
    color: var(--ion-color-tertiary-shade);
    font-weight: bold;
    border-bottom: 2px solid;
}

.table-responsive {
    overflow: auto;

    table {
        width: 100%;

        thead {
            background-color: var(--ion-color-medium);
        }

        tbody tr {
            background-color: #fff;
            transition: all .125s ease-in-out;

            &:nth-child(2n-1) {
                background-color: #f5f5f5;
            }

            &:hover {
                background-color: rgba(208, 208, 208, 0.3);
            }
        }

        td,
        th {
            padding: 0.75em 1.5em;
            font-weight: 600;
            color: #555;
        }

        th {
            font-weight: bold;
            color: #fff;
            white-space: nowrap;
        }
    }
}

input.dummy {
    position: absolute;
    top: -100px;
}

.contact {
    margin: 5px 0;

    h5 {
        margin: 0;
        font-weight: 600;
    }

    ion-text {
        line-height: 1.5;
    }
}

ion-col.no-padding {
    padding: 0;
}

.bg-content {
    background: white;
}

.mb-50 {
    margin-bottom: 50px;
}

.author {
    display: flex;
    align-items: center;
    color: var(--ion-color-dark);
    text-decoration: none;
    padding: 10px 0;
    max-width: 100%;

    img {
        height: 50px;
        max-width: 100%;
    }

    h1 {
        margin: 5px 0 0 0;
        font-family: var(--primary-font-family);
        font-size: 50px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 1px;
    }
}

.box-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.page-container {
    min-height: 600px;

    .title-bar {
        text-align: center;

        h6 {
            margin-bottom: 0;
        }

        h1 {
            margin-top: 10px;
            color: var(--ion-color-primary);
            font-weight: bold;
            font-size: 30px;
            letter-spacing: 1.5;
            line-height: 1.2;
        }
    }

    .sidebar {
        max-width: 400px;
    }
}

.screen-hidden {
    display: none;
}


@media print {
    ion-app.md.ion-page {
        display: none;
    }
    body.backdrop-no-scroll{
        overflow: auto;
        position: relative;
        height: auto;
    }
    #print-view{
        display: block;
        .screen-hidden {
            display: block;
        }
    }
}
#print-view{
    ion-badge{
        display: none;
    }
}

#standalone-page {

    @media screen {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

        .box {
            background: var(--ion-color-light);
            box-shadow: 0px 1px 4px 0px #000;
        }
    }

    .box {
        text-align: center;
        padding: 50px;
        margin: 0 20px;
    }
}
@media screen and (max-width:425px) {
    .mobile-invisible{
        display: none;
    }
}
table{
    thead{
        border-bottom: 2px solid;
    }
    td,th{
        padding: 10px;
    }
    tbody{
        tr:hover{
            background: var(--ion-color-light-shade);
        }
    }
}
