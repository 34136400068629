.quick-view-card{
    text-decoration: none;
}
.quick-view {
    .swiper-slide {
        text-align: center;
        background: var(--ion-color-light-shad);

        a {
            display: block;
        }
    }

    .pricing {
        line-height: 1.3;
        font-family: var(--primary-font-family);
        margin-bottom: 10px;

        .regular {
            text-decoration: line-through;
            margin-left: 20px;
        }

        .sale {
            font-size: 30px;
            font-weight: 700;
        }
    }

    .quantity {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        ion-input {
            text-align: center;
            input{
                padding: 10px;
            }
        }

        >* {
            margin-bottom: 10px;
        }
    }
    .cat{
        a{
            text-decoration: none;
        }
        svg{
            margin: 0 10px;
        }
    }
}

@media screen and (min-width:426px) {
    ion-modal.quick-view {
        --width: 700px;
        --height: 500px;
    }
}

@media screen and (min-width:769px) {
    ion-modal.quick-view {
        --width: 900px;
        --height: 600px;
    }
}
