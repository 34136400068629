.cart-product {

    border: 2px solid rgb(231, 231, 231);
    background-color: white;
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    .cover-img {
        cursor: pointer;
        height: 5rem;
        width: 5rem;
        background-color: white;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .info {
        cursor: pointer;
        width: 57%;
        margin: 0 10px;
        justify-items: start;
        h6 {
            margin-bottom: 0;
            font-weight: bold;
            font-size: 0.9rem;
            letter-spacing: -0.03rem;
            text-transform: capitalize;
        }
        p{
            margin: 5px 0 0;
            font-size: 0.79rem;
        }
        .attribute{
            display: flex;
            p{
                margin-right: 10px;
            }
        }
        .qty-price{
            display: flex;
            align-items: center;
            margin: 5px 0;
            p{
                margin: 0 0 0 20px;
                font-size: 0.95rem;
            }
        }
    }

    .price {
        display: flex;
        align-items: center;
    }

    &:hover {
        background: var(--ion-color-light-shade);
    }
}
