.flash-sale {
    margin: 30px 0;
    hr{
        background: var(--ion-color-primary);
        width: 100px;
        height: 3px;
        margin-bottom: 20px;
    }
    section.title {
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        .f{
            margin-right: 10px;
            color: var(--ion-color-coffee);
        }
        ion-text {
            font-family: var(--primary-font-family);
            font-weight: 700;
            font-size: 60px;
            font-style: italic;
        }
    }
    .swiper-slide{
        padding-bottom: 5px;
    }
}
