section.h-products{
    .title{
        font-family: var(--primary-font-family);
        font-weight: 700;
        font-size: 30px;
        margin-left: 10px;
    }

    .head{
        border-bottom: 1px solid #ddd;
        margin-bottom: 15px;
    }
    .swiper {
        margin: 0 5px;
    }
}
