section.v-products-box {
    background: white;
    border: 10px solid var(--ion-color-primary);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    .title {
        font-family: var(--primary-font-family);
        font-weight: 700;
        font-size: 25px;
        background: var(--ion-color-primary);
        color: var(--ion-color-light);
        text-align: center;
        padding-top: 5px;
        padding-bottom: 15px;
    }

    .swiper-wrapper {
        height: 430px;
        padding-top: 10px;

        div.swiper-slide {
            height: 100px !important;
        }
    }

    .btn-container {
        text-align: center;
        padding: 10px;
    }
}
