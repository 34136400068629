figure.zoomable {
    background-repeat: no-repeat;
    background-size: 0%;
    margin: 0;
    position: relative;

    ion-button {
        position: absolute;
        top: 0;
        right: 20px;
        z-index: 100;
    }

    @media screen and (min-width:769px) {
        &:hover {
            background-size: 150%;

            img {
                opacity: 0;
            }
        }
    }

    @media screen and (max-width:768px) {
        background: none !important;
    }
}
