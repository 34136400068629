.product {
    //single product flash
    margin: 0;

    padding: 0;
    background: #efefef;

    a {
        display: block;
        text-align: center;
    }

    h2 {
        text-overflow: ellipsis;
        padding: 0 10px 0;
        font-family: var(--secondary-font-family);
        font-size: 14px;
        line-height: 1.5em;
        height: 3em;
        overflow: hidden;
        letter-spacing: 1px;
        color: var(--ion-color-dark-shad);
        margin: 0 0 14px;
        font-weight: 600;
    }

    .pricing {
        line-height: 1.3;
        font-family: var(--primary-font-family);
        padding: 0 10px 10px;

        .regular {
            text-decoration: line-through;
        }

        .sale {
            font-size: 16px;
            font-weight: 700;
            margin-left: 5px;
        }
    }

    .head {
        ion-buttons {
            justify-content: space-evenly;
            transition: bottom 0.5s;
            background: white;
        }
    }
    @media screen and (min-width:769px) {
        .head {
            position: relative;
            overflow: hidden;

            ion-buttons {
                position: absolute;
                width: 98%;
                bottom: -50px;
                left: 1%;
            }
        }

        &:hover .head ion-buttons {
            bottom: 10px;
        }
    }

}
