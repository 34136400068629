#category-sidebar {
    position: relative;

    .title {
        color: white;
        background:var(--ion-color-coffee);
        padding: 10px 20px;
        font-weight: bold;
        letter-spacing: 3px;
        text-align: center;
    }

    .subjects {
        background: var(--ion-color-light);
        width: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: -1;
        transition: 0.5s width, 0.5s opacity;
        height: 100%;

        &.show {
            width: 300px;
            left: 100%;
            opacity: 1;
            z-index: 2;
        }
    }
}
