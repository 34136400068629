.radio-box{
    background: var(--ion-item-background, var(--ion-background-color, #fff));
    padding: 20px;
    display: flex;
    justify-content: space-between;
    ion-radio-group{
        display: flex;
        align-items: center;
        justify-content: center;
        ion-radio{
            margin-left: 30px;
            margin-right: 10px;
        }
    }
}
#checkout-body{
    --background: rgba(var(--ion-color-tertiary-rgb, 0.5), 0.3);
    ion-item{
        padding: 10px 10px 0;
    }
    .radio-box{
        margin: 10px 10px 0;
    }
}
