#home-page{
    ion-card{
        margin:0 0 20px;
        ion-card-content{
            padding: 0 10px;
        }
    }
    .h-products{
        margin: 30px 0;
        .title{
            margin: 0;
        }
    }
}
.home-slider{
    ion-col{
        height: 450px;
        > div{
            height: 100%;
        }
        .mini-slider{
            img{
                object-fit: cover;
                width: 100%;
            }
        }

        @media only screen and (max-width: 1441px) {
            height: 340px;
        }
        @media only screen and (max-width: 1025px) {
            height: 250px;
        }
    }
}
