#single-product {
    padding: 10px;
    .row{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .col{
            width: 32%;
            @media screen and (max-width:1024px) {
                width: 48%;
                &.category{
                    display: none;
                }
            }
            @media screen and (max-width:425px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
    .swiper-slide {
        text-align: center;
        background: var(--ion-color-light-shad);

        a {
            display: block;
        }
    }
    .title{
        margin-top: 0;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 0px;

    }
    .meta{
        .pricing {
            line-height: 1.3;
            font-family: var(--primary-font-family);
            margin-bottom: 10px;

            .regular {
                text-decoration: line-through;
                margin-left: 20px;
            }

            .sale {
                font-size: 30px;
                font-weight: 700;
            }
        }
    }

    .quantity {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        ion-input {
            text-align: center;

            input {
                padding: 10px;
            }
        }

        >* {
            margin-bottom: 10px;
        }
    }

    .cat {
        a {
            text-decoration: none;
        }

        svg {
            margin: 0 10px;
        }
    }
    .description{
        .category{
            display: none;
            @media screen and (max-width:1024px) {
                display: block;
            }
        }
    }
}
