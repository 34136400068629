#cart-modal{
    --width: 500px;
    --height: 100%;
    justify-content: end;
    ion-footer{
        border-top: 2px solid var(--ion-color-primary);
    }
}
#checkout-modal{
    --min-height: 90vh;
    ion-footer{
        border-top: 2px solid var(--ion-color-primary);
    }
}
